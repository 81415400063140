import client from '../config/client';

/**
 * @param {646705db-a7c5-4a11-9bbe-214ffdb6c117} headquarterId 
 * @returns 
 */
export const getWholesalerBasicInfo = (headquarterId) => {
    return new Promise((resolve, reject) => {
        client.get(`headquarters/${headquarterId}`)
        .then((response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
};

/**
 * @param {646705db-a7c5-4a11-9bbe-214ffdb6c117} headquarterId 
 * @param {646705db-a7c5-4a11-9bbe-214ffdb6c117} branchOfficeId
 * @param {{ start_date: '2022-06-23', end_date: '2022-06-23', full_history: true }} queries 
 * @returns 
 */
export const getWholesalerSalesBranch = (headquarterId, branchOfficeId, queries = {}) => {
    const url = `headquarters/${headquarterId}/branch-offices/${branchOfficeId}${ 
        queries && Object.keys(queries).length > 0 ? 
        Object.keys(queries).reduce(
            (queryString, queryName, index) => `${queryString}${index !== 0 ? '&' : ''}${queryName}=${queries[queryName]}`, '?',
        )
        : ''
    }`
    return new Promise((resolve, reject) => {
        client.get(url)
        .then((response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
};

/**
 * @param {646705db-a7c5-4a11-9bbe-214ffdb6c117} headquarterId 
 * @param {646705db-a7c5-4a11-9bbe-214ffdb6c117} branchOfficeId 
 * @param {{ start_date: '2022-06-23', end_date: '2022-06-23' }} queries 
 * @returns 
 */
export const downloadWholesalerSalesReport = (headquarterId, branchOfficeId, queries = {}) => {
    const url = `headquarters/${headquarterId}/branch-offices/${branchOfficeId}/download-sales-report${ 
        queries && Object.keys(queries).length > 0 ? 
        Object.keys(queries).reduce(
            (queryString, queryName, index) => `${queryString}${index !== 0 ? '&' : ''}${queryName}=${queries[queryName]}`, '?',
        )
        : ''
    }`
    return new Promise((resolve, reject) => {
        client.get(url)
        .then((response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
};