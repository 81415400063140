import React from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow, 
  TableCell,
  TableHead,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import moment from 'moment'; 

import {
  Text,
  Button,
  CheckBox
} from '../../components';
import images from '../../assets/images';

export const HomePage = ({
  classes,
  headCells,
  minDate,
  setMinDate,
  maxDate,
  setMaxDate,
  branchName,
  dataBranch,
  fullHistory,
  setFullHistory,
  downloadQR
}) => {
  const tableElements = () => {
    let fiveElements = dataBranch.sales_summary.sales.length <= 5 ? dataBranch.sales_summary.sales : dataBranch.sales_summary.sales.slice(0, 4);
    return fiveElements.map(sales => {
      return (
        <TableRow>
          <TableCell>
            <Text className={classes.textCellTable}>
              {sales.shop_name}
            </Text>
          </TableCell>
          <TableCell>
            <Text className={classes.textCellTable}>
              {sales.shop_owner_name}
            </Text>
          </TableCell>
          <TableCell>
            <Text className={classes.textCellTable}>
              {sales.phone_number.slice(3)}
            </Text>
          </TableCell>
          <TableCell>
            <Text className={classes.textCellTable}>
              {moment(sales.movement_date).format('DD MMM YYYY hh:mm a')}
            </Text>
          </TableCell>
          <TableCell>
            <Text className={classes.textCellTable}>
              $ {sales.amount}
            </Text>
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <div>
      <div 
        className={classes.page}
      >
        <div className={classes.topPage}>
          <div className={classes.topContainer}>
            <div className={classes.titleContainer}>
              <Text className={classes.title} variant="h2" color="initial" aling="justify">Historial del mes ({branchName.trim()})</Text>
            </div>
            <div className={classes.topSecondContainer}>
              <div className={classes.calendarContainer}>
                  <div className={classes.buttonCalendar}>
                    <Text className={classes.textButton}>Desde</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                      <KeyboardDatePicker
                        maxDate={maxDate}
                        value={minDate}
                        onChange={value => setMinDate(value)}
                        animateYearScrolling
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className={classes.buttonCalendar}>
                    <Text className={classes.textButton}>Hasta</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                      <KeyboardDatePicker
                        minDate={minDate}
                        value={maxDate}
                        onChange={value => setMaxDate(value)}
                        animateYearScrolling
                      />
                    </MuiPickersUtilsProvider>
                  </div>
              </div>
              <div className={classes.containerCheckBox}>
                <CheckBox onClick={setFullHistory} checked={fullHistory} />
                <Text className={classes.textCheckBox}>Historial completo</Text>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.informationContainer}>
          <div className={classes.information}>
            <Text className={classes.titleInformation} aling="center" color="initial" >Total vendido</Text>
            <Text className={classes.textSouldInformation} aling="center" color="initial">
              $ { dataBranch?.sales_summary && dataBranch.sales_summary?.total_sales_amount ? dataBranch.sales_summary.total_sales_amount : 0 }
            </Text>
          </div>
          <div className={classes.information}>
            <Text className={classes.titleInformation} aling="center" color="initial" >Ventas realizadas</Text>
            <Text className={classes.textSalesMadeInformation} aling="center" color="initial">
              { dataBranch?.sales_summary && dataBranch.sales_summary?.total_sales ? dataBranch.sales_summary.total_sales : 0 }
            </Text>
          </div>
        </div>
      </div>
      <div className={classes.bottomPage}>
        <div className={classes.leftBottomPage}>
          <Text className={classes.qr} aling="justify" color="initial" variant="h2" >QR de sucursal</Text>
          <img src={dataBranch?.qr_path || images.codigoQR} className={classes.imgQR} alt="imag-codeQR"/>
          <Button customButtonStyle={styles.button} onClick={() => downloadQR()}>Descargar QR</Button>
        </div>
        <div className={classes.rigthBottomPage}>
          <div className={classes.titleTransaction}>
            <Text className={classes.textTransaction}>Transacciones más recientes</Text>
          </div>
          <TableContainer>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      align="center"
                      scope="col"
                    >
                      <Text className={classes.titleLabel}>{headCell.label}</Text>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                { 
                  dataBranch?.sales_summary && dataBranch.sales_summary?.sales && dataBranch.sales_summary.sales.length > 0 &&
                  tableElements() 
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

const styles = {
  button: {
    backgroundColor: '#ffffff',
    border: '1px solid #3050ff',
    fontSize: 12,
    borderRadius: 10,
    color: '#3050ff',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 20,
  },
  buttonStyle: {
    backgroundColor: '#f9f9f9',
    border: '1px solid #f9f9f9',
    borderRadius: 10,
    paddingLeft: 0,
    paddingRight: 0,
  },
}

HomePage.propTypes = {
  classes: PropTypes.object,
  headCells: PropTypes.array,
  minDate: PropTypes.string,
  setMinDate: PropTypes.func,
  maxDate: PropTypes.string,
  setMaxDate: PropTypes.func,
  branchName: PropTypes.string,
  dataBranch: PropTypes.object,
  fullHistory: PropTypes.bool,
  setFullHistory: PropTypes.func
};

HomePage.defaultProps = {
  classes: {},
  headCells: [],
  minDate: '',
  setMinDate: () => { },
  maxDate: '',
  setMaxDate: () => { },
  branchName: '',
  dataBranch: {},
  fullHistory: false,
  setFullHistory: () => { }
};
