import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ErrorHandlerUI from './ErrorHandler';

class ErrorHandler extends Component {
    state = {
        isVisible: false,
        message: ''
    }

    async close() {
        this.hide();
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    componentDidMount() {
        const { reference } = this.props;
        if (reference) reference(this);
    }

    async show(message) {
        if (message && message.response && message.response.data && message.response.data.readable_message) {
          message = message.response.data.readable_message
        } else if (message && message.response && message.response.data && message.response.data.message) {
            message = message.response.data.message
        } else {
            message = JSON.stringify(message.message);
        }

        await this.setState({
            isVisible: true,
            message
        });
    }

    async hide() {
        await this.setState({
            isVisible: false
        });
    }

    render() {
        return(
            <ErrorHandlerUI
                isVisible={this.state.isVisible}
                message={this.state.message}
                close={() => this.close()}
                actionText={this.props.actionText}
            />
        )
    }
}

export default ErrorHandler;

ErrorHandler.propTypes = {
    reference: PropTypes.any
};
