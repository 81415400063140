import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tabBar: {
    display: 'flex',
    height: '64px',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    justifyContent: 'space-between'
  },
  containerBody: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row'
  },
  drawerTab: {
    display: 'flex',
    height: '100%',
    width: '220px',
    flexDirection: 'column'
  },
  tabBarIcon: {
    width: '57px',
    height: '34px',
  },
  selectShopContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  warehouseIcon: {
    width: '34px',
    height: '34px',
    marginRight: 15
  },
  selectStorageContainer: {
    width: '200px',
    verticalAlign: 'center'
  },
  childrenContainer: { 
    backgroundColor: '#f0f0f0', 
    width: '100%' 
  },
  drawerElementContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 15
  },
  iconConatainer: {
    marginRight: 10
  },
  selectedText: {
    color: '#0253cc'
  },
  unselectedText: {
    color: '#858d9c',
  }
}));

export default useStyles;