import React from 'react';
import PropTypes from 'prop-types';

import Images from '../../assets/images';
import { BoxLayout, Button } from '../../components';

export const NotFoundPage = ({ handleBack, classes }) => {
  return (
    <BoxLayout className={ classes.page }>
      <BoxLayout className={ classes.container }>
        <img src={ Images.image404 } alt="not-found-img" />
        <Button variant="contained" color="primary" onClick={ handleBack }>Regresar</Button>
      </BoxLayout>
    </BoxLayout>
  );
};

export default NotFoundPage;

NotFoundPage.propTypes = {
  handleBack: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};
