import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import PageContainerUI from './PageContainer';
import useStyles from './PageContainer.styles';
import { drawerData } from '../../constants/constants';
import { 
    getWholesalerBasicInfo,
    selectBranchOffice
} from '../../redux/actions/wholesaler.action';

const PageContainer = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();

    const [branchOfficeIdSelected, setBranchOfficeIdSelected] = useState('');

    const {
        basicInfoResponse,
    } = useSelector((state) => state.wholesalerReducer);

    useEffect(() => {
        const headquarterId = localStorage.getItem('headquarter_id');
        if(headquarterId) dispatch(getWholesalerBasicInfo(headquarterId));
    }, []);

    useEffect(() => {
        if(Object.keys(basicInfoResponse).length !== 0) {
            if(basicInfoResponse?.data && basicInfoResponse.data?.branch_offices && basicInfoResponse.data.branch_offices.length !== 0) {
                setBranchOfficeIdSelected(basicInfoResponse.data.branch_offices[0].branch_office_id)
            }
        }
    }, [basicInfoResponse])

    useEffect(() => {
      if(branchOfficeIdSelected !== '') {
        let branchOfficeData = (basicInfoResponse.data?.branch_offices && basicInfoResponse.data.branch_offices.length !== 0) ? basicInfoResponse.data.branch_offices.filter(branch => branch.branch_office_id === branchOfficeIdSelected) : [];
        let branchOfficeDataSelected = branchOfficeData.length !== 0 ? branchOfficeData[0] : {}; 
        dispatch(selectBranchOffice(branchOfficeDataSelected));
      }
    }, [branchOfficeIdSelected])
    

    const handleChangeInput = () => (event) => {
        let { value } = event.target;
        console.log(value)
        setBranchOfficeIdSelected(value)
    };

    return(
        <PageContainerUI 
            {...props} 
            classes={classes} 
            drawerData={drawerData.slice(0,2)} 
            branchOfficeIdSelected={branchOfficeIdSelected}
            handleChangeInput={handleChangeInput}
            location={location}
            branchOffices={basicInfoResponse?.data && basicInfoResponse.data?.branch_offices && basicInfoResponse.data.branch_offices.length !== 0 ? basicInfoResponse.data.branch_offices : []}
        />
    )
}

export default PageContainer;