import { combineReducers } from 'redux';

import authenticationReducer from './authentication.reducer';
import wholesalerReducer from './wholesaler.reducer';

const rootReducer = combineReducers({
  authenticationReducer,
  wholesalerReducer
});

export default rootReducer;
