import React from 'react';
import PropTypes from 'prop-types';

import NotFoundPageUI from './NotFoundPage';
import useStyles from './NotFoundPage.style';

const NotFoundPage = (props) => {
    const classes = useStyles();
    const handleBack = () => {
        const { history } = props;
        history.goBack();
    };

    return <NotFoundPageUI  handleBack={() => handleBack()} classes={ classes } /> 
}

export default NotFoundPage;

NotFoundPage.propTypes = {
    history: PropTypes.shape({
      goBack: PropTypes.func,
    }).isRequired,
};