import React from 'react';
import PropTypes from 'prop-types';

import SuccessMessageUI from './SuccessMessage';
import useStyles from './SuccessMessages.styles';

const SuccessMessage = ({
    message,
    onClose
}) => {
    const classes = useStyles();

    return <SuccessMessageUI 
        classes={ classes }
        message={ message }
        onClose={ onClose }
    />
}

export default SuccessMessage;

SuccessMessage.propTypes = {
    message: PropTypes.string
}

SuccessMessage.defaultProps = {
    message: '',
    onClose: () => {},
};