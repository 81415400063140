import React from 'react';

import TextInputUI from './TextInput';

const TextInput = (props) => {
    return(
        <TextInputUI {...props} />
    )
}

export default TextInput;