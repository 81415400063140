import client from '../config/clientAuthentication';

/**
 * @param {{ account_type_id: 'WS', phone_number: '+525587654321', pin: '981201' }} body 
 * @returns 
 */
export const wholesalersAuthentication = (body) => {
    return new Promise((resolve, reject) => {
        client.post('users/authenticate', body)
        .then((response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
};