import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import routes from './routes';
import './styles.scss';
import buildStore from './store';

const { store } = buildStore();

ReactDOM.render(
  <Provider store={store}>
    {routes({ store })}
  </Provider>,
  document.getElementById('root'),
);