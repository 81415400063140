import * as ACTION from '../actionTypes/wholesaler';

const dataState = {
    loading: {
        GET_BASIC_INFO: false,
        GET_SALES_BRANCH: false,
        DOWNLOAD_REPORT: false
    },
    error: {
        GET_BASIC_INFO: null,
        GET_SALES_BRANCH: null,
        DOWNLOAD_REPORT: null
    },
    basicInfoResponse: {},
    branchOfficeSelected: {},
    salesBranchResponse: {},
    downloadReportResponse: {}
};

const wholesalerReducer = (state = dataState, action) => {
    switch (action.type) {
        case ACTION.INIT_GET_BASIC_INFO:
            return { ...state, loading: { ...state.loading, GET_BASIC_INFO: true }, error: { ...state.error, GET_BASIC_INFO: null } };
        case ACTION.RESPONSE_GET_BASIC_INFO:
            return { ...state, loading: { ...state.loading, GET_BASIC_INFO: false }, error: { ...state.error, GET_BASIC_INFO: null }, basicInfoResponse: action.result };
        case ACTION.ERROR_GET_BASIC_INFO:
            return { ...state, loading: { ...state.loading, GET_BASIC_INFO: false }, error: { ...state.error, GET_BASIC_INFO: action.error } };
        case ACTION.RESET_GET_BASIC_INFO:
            return { ...state, loading: { ...state.loading, GET_BASIC_INFO: false  }, error: { ...state.error, GET_BASIC_INFO: null  }, basicInfoResponse: {} };

        case ACTION.SELECT_BRANCH_OFFICE:
            return { ...state, branchOfficeSelected: action.result };
        
            case ACTION.INIT_GET_SALES_BRANCH:
            return { ...state, loading: { ...state.loading, GET_SALES_BRANCH: true }, error: { ...state.error, GET_SALES_BRANCH: null } };
        case ACTION.RESPONSE_GET_SALES_BRANCH:
            return { ...state, loading: { ...state.loading, GET_SALES_BRANCH: false }, error: { ...state.error, GET_SALES_BRANCH: null }, salesBranchResponse: action.result };
        case ACTION.ERROR_GET_SALES_BRANCH:
            return { ...state, loading: { ...state.loading, GET_SALES_BRANCH: false }, error: { ...state.error, GET_SALES_BRANCH: action.error } };
        case ACTION.RESET_GET_SALES_BRANCH:
            return { ...state, loading: { ...state.loading, GET_SALES_BRANCH: false  }, error: { ...state.error, GET_SALES_BRANCH: null  }, salesBranchResponse: {} };
  
        case ACTION.INIT_DOWNLOAD_REPORT:
            return { ...state, loading: { ...state.loading, DOWNLOAD_REPORT: true }, error: { ...state.error, DOWNLOAD_REPORT: null } };
        case ACTION.RESPONSE_DOWNLOAD_REPORT:
            return { ...state, loading: { ...state.loading, DOWNLOAD_REPORT: false }, error: { ...state.error, DOWNLOAD_REPORT: null }, downloadReportResponse: action.result };
        case ACTION.ERROR_DOWNLOAD_REPORT:
            return { ...state, loading: { ...state.loading, DOWNLOAD_REPORT: false }, error: { ...state.error, DOWNLOAD_REPORT: action.error } };
        case ACTION.RESET_DOWNLOAD_REPORT:
            return { ...state, loading: { ...state.loading, DOWNLOAD_REPORT: false  }, error: { ...state.error, DOWNLOAD_REPORT: null  }, downloadReportResponse: {} };
        
        default:
            return state;
    }
};

export default wholesalerReducer;