import React from 'react';

import MenuItemUI from './MenuItem';

const MenuItem = (props) => {
    return(
        <MenuItemUI {...props} />
    )
}

export default MenuItem;