
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    borderWidth: 10,
    padding: 20,
    backgroundColor: '#D1D1D1'
  },
  elementMessage: {
    marginTop: 20
  },
  button: {
    marginTop: 40
  }
}));

export default useStyles;
