import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  page: {
    backgroundColor: '#f0f0f0',
    padding: 20,
  },
  viewPage: {
    backgroundColor: '#ffffff',
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20
  },
  bottomPage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    color: '#1b253a',
    fontSize: 16,
  },
  topSecondContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  calendarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f9f9f9',
    padding: 12,
    borderRadius: 10,
    marginLeft: 15
  },
  buttonCalendar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textButton: {
    color: '#94979b',
    marginRight: 10,
    marginLeft: 10,
    fontSize: 12
  },
  tableContainer: {
    width: '100%',
    marginTop: 30
  },
  tableHead: {
    backgroundColor: '#f9f9f9',
  },
  textCellTable: {
    textAlign: 'center',
    color: '#52575a',
    fontSize: 14,
    margin: 5,
  },
  titleLabel:{
    color: '#202122',
    fontSize: 14,
    paddingTop: 5,
    paddingBottom: 5
  },
}));

export default useStyles;