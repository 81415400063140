export default {
    image404: require('./404-image.jpg'),
    tabBarIcon: require('./YoFio_Logotipo.png'),
    warehouse: require('./warehouse.png'),
    inicio_on: require('./Inicio_on.png'),
    inicio_off: require('./Inicio_off.png'),
    ventas_on: require('./Ventas_on.png'),
    ventas_off: require('./Ventas_off.png'),
    transfer_on: require('./Inicio_on.png'),
    transfer_off: require('./Transfer_off.png'),
    comisiones_on: require('./Inicio_on.png'),
    comisiones_off: require('./comisiones_off.png'),
    codigoQR: require('./CodigoQR.png'),
    iconCalendar: require('./IconCalendar.png'),
};
    