import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  page: {
    backgroundColor: '#f0f0f0',
    padding: 20,
  },
  topPage: {
    backgroundColor: '#ffffff',
    marginBottom: 20,
    padding:20
  },
  bottomPage: {
    display: 'flex',
    flexDirection: 'row',
    padding: 20
  },
  leftBottomPage: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    width: '180px',
    padding: 20,
    marginRight: 20
  },
  rigthBottomPage: {
    backgroundColor: '#ffffff',
    width: '800px',
    height: '460px'
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContainer: {
    marginRight: 20,
  },
  title: {
    color: '#1b253a',
    fontSize: 16,
  },
  topSecondContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  containerCheckBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f9f9f9',
    padding: 5,
    borderRadius: 10,
    marginLeft: 20,
  },
  textCheckBox :{
    color: '#4a4b4b',
    fontSize: 15
  },
  calendarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f9f9f9',
    padding: 12,
    borderRadius: 10
  },
  buttonCalendar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textButton: {
    color: '#94979b',
    marginRight: 10,
    marginLeft: 10,
    fontSize: 12
  },
  information: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '20px'
  },
  informationContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  },
  titleInformation: {
    backgroundColor: '#f9f9f9',
    color: '#50565d',
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 3,
    width: '195px',
    paddingTop: 20,
    paddingBottom: 20,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  textSouldInformation: {
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    color: '#1b253a',
    fontSize: 31,
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: 'center',
    width: '195px',
    borderBottom: '5px solid #9471ff',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  textReceivedInformation: {
    backgroundColor: '#f9f9f9',
    color: '#1b253a',
    fontSize: 31,
    textAlign: 'center',
    width: '195px',
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: '5px solid #3050ff',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  textForRecievingInformation : {
    backgroundColor: '#f9f9f9',
    color: '#1b253a',
    fontSize: 31,
    textAlign: 'center',
    width: '195px',
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: '5px solid #62a9c7',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  textSalesMadeInformation: {
    backgroundColor: '#f9f9f9',
    color: '#1b253a',
    fontSize: 31,
    textAlign: 'center',
    width: '195px',
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: '5px solid #3050ff',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  textCommissionInformation: {
    backgroundColor: '#f9f9f9',
    color: '#1b253a',
    fontSize: 31,
    textAlign: 'center',
    width: '195px',
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: '5px solid #20b223',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  qr: {
    color: '#50565d',
    textAlign: 'justify',
    fontSize: 14,
    fontWeight: 'bold'
  },
  imgQR: {
    width: '100%',
    height: '180px',
    paddingTop: 20,
  },
  titleTransaction: {
    padding: 30
  },
  textTransaction: {
    color: '#50565d',
    fontSize: 14, 
  },
  tableHead: {
    backgroundColor: '#f9f9f9',
  },
  textCellTable: {
    textAlign: 'center',
    color: '#52575a',
    fontSize: 14,
    margin: 5
  },
  titleLabel:{
    color: '#202122',
    fontSize: 14,
    paddingTop: 5,
    paddingBottom: 5
  },
}));

export default useStyles;