import React from 'react';
import PropTypes from 'prop-types';

import {
  Text,
} from '../../components';

export const Transfer = ({
  classes
}) => {
  return(
    <div 
      className={classes.page}
    >
      <Text>Transferencias</Text>
    </div>
  );
};

export default Transfer;

Transfer.propTypes = {
  classes: PropTypes.object
};

Transfer.defaultProps = {
  classes: {}
};
