import React from 'react';
import BoxM from '@material-ui/core/Box';

const BoxLayout = ({ children, ...others }) => (
  <BoxM { ...others }>
    {children}
  </BoxM>
);

export default BoxLayout;
