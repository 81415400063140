import * as ACTION from '../actionTypes/authentication';

const dataState = {
    loading: {
        AUTHENTICATION: false,
    },
    error: {
        AUTHENTICATION: null,
    },
    authenticationResponse: {}
};

const authenticationReducer = (state = dataState, action) => {
    switch (action.type) {
        case ACTION.INIT_AUTHENTICATION:
            return { ...state, loading: { ...state.loading, AUTHENTICATION: true }, error: { ...state.error, AUTHENTICATION: null } };
        case ACTION.RESPONSE_AUTHENTICATION:
            return { ...state, loading: { ...state.loading, AUTHENTICATION: false }, error: { ...state.error, AUTHENTICATION: null }, authenticationResponse: action.result };
        case ACTION.ERROR_AUTHENTICATION:
            return { ...state, loading: { ...state.loading, AUTHENTICATION: false }, error: { ...state.error, AUTHENTICATION: action.error } };
        case ACTION.RESET_AUTHENTICATION:
            return { ...dataState };
        default:
            return state;
    }
};

export default authenticationReducer;