import * as ACTION from '../actionTypes/authentication';
import * as authenticationService from '../../services/authentication.service';

export const wholesalersAuthentication = (body) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_AUTHENTICATION,
    });

    try {
        const authenticationResponse = await authenticationService.wholesalersAuthentication(body);
        if (authenticationResponse) {
            dispatch({
                type: ACTION.RESPONSE_AUTHENTICATION,
                result: authenticationResponse,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_AUTHENTICATION,
            error: error,
        });
    }
};

export const resetWholesalersState = () => async (dispatch) => {
    dispatch({
        type: ACTION.RESET_AUTHENTICATION,
    });
};