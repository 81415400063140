import React from 'react';
import { Link } from 'react-router-dom';

import {
    TextInput,
    MenuItem,
    Text
} from '../../components'
import Images from '../../assets/images';

const PageContainer = ({ 
    children, 
    classes, 
    drawerData,
    branchOfficeIdSelected,
    handleChangeInput,
    location,
    branchOffices
}) => {
    const iconComponent = (val, path) => {
        let srcIcon = '';
        switch (val) {
          case 'inicio':
            srcIcon = location.pathname.includes(path) ? Images.inicio_on : Images.inicio_off;
            break;
          case 'ventas':
            srcIcon = location.pathname.includes(path) ? Images.ventas_on : Images.ventas_off;
            break;
          case 'transferencias':
            srcIcon = location.pathname.includes(path) ? Images.transfer_on : Images.transfer_off;
            break;
          case 'comisiones':
            srcIcon = location.pathname.includes(path) ? Images.comisiones_on : Images.comisiones_off;
            break;
          default:
            break;
        }
        return <img width={25} height={25} src={srcIcon} alt="" />
    }

    const nameComponent = (name, val, path) => {
        return (
          <Link
            className={classes.link}
            to={{
              pathname: path,
              query: { route: name },
            }}
          >
            <Text className={ location.pathname.includes(path) ? classes.selectedText : classes.unselectedText } primary="Drafts">{name}</Text>
          </Link>
        );
    }
    
    const drawerElement = () => {
        return drawerData.map(({ name, val, path }) => {
            return(
            <div className={classes.drawerElementContainer}>
                <div className={classes.iconConatainer}>
                {iconComponent(val, path)}
                </div>
                <div>
                {nameComponent(name, val, path)}
                </div>
            </div>
            )
        })
    }

    return(
        <>
            <div className={classes.tabBar}>
                <img 
                src={Images.tabBarIcon}
                className={classes.tabBarIcon}
                />
                <div
                className={classes.selectShopContainer}
                >
                <img 
                    src={Images.warehouse}
                    className={classes.warehouseIcon}
                />
                <TextInput
                    className={classes.selectStorageContainer}
                    variant="standard"
                    label="Elige una opción"
                    select
                    value={branchOfficeIdSelected}
                    onChange={handleChangeInput()}
                > 
                  { branchOffices.keys !== 0 ?
                      branchOffices.map(branch => (
                        <MenuItem value={branch.branch_office_id}>{branch.trade_name}</MenuItem>
                      ))
                    :
                    <MenuItem value={''}>Sin sucursales</MenuItem>
                  }
                </TextInput>
                </div>
            </div>
            <div className={classes.containerBody}>
                <div className={classes.drawerTab}>
                { drawerElement() }
                </div>
                <div className={classes.childrenContainer} >
                { children }
                </div>
            </div>
        </>
    )
}

export default PageContainer;