export const monthNamesFull = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
export const monthNames = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
export const daysOfMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 18, 20, 21, 22, 23, 24, 25, 26, 27, 28, 28, 30];
export const dayNames = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
export const dayNamesMap = {MONDAY: 'Lunes', TUESDAY: 'Martes', WEDNESDAY: 'Miércoles', THURSDAY: 'Jueves', FRIDAY: 'Viernes', SATURDAY: 'Sábado', SUNDAY: 'Domingo'};
export const transactionType = {
  Buy: 'Compra', Pay: 'Abono YoFio', Wd: 'Liquidación', Dp: 'Nota de cobro',
};
export const statusType = {
  APPROVED: 'Aprobado',
  PENDING: 'Pendiente',
  DONE: 'Hecho',
  PAID: 'Pagado',
  COLLECTED: 'Cobrado',
  LATE: 'Atrasado',
  INACTIVE: 'Inactivo',
  CURRENT: 'Al corriente',
  REVIEWING: 'Revisando',
  'PARTLY PAID': 'Parcialmente pagado',
  'PARTLY COLLECTED': 'Parcialmente cobrado',
};
export const colorStatus = {
  APPROVED: '#0253cc',
  PENDING: '#ee4e52',
  DONE: '#009805',
  PAID: '#009805',
  COLLECTED: '#0253cc',
  LATE: '#ee4e52',
  INACTIVE: '#ee4e52',
  CURRENT: '#009805',
  REVIEWING: 'Revisando',
  'PARTLY PAID': '#0253cc',
  'PARTLY COLLECTED': '#0253cc',
};

export const preferredPaymentDayOptions = [
  {
    title: 'Lunes',
    value: 'MONDAY'
  },
  {
    title: 'Martes',
    value: 'TUESDAY'
  },
  {
    title: 'Miércoles',
    value: 'WEDNESDAY'
  },
  {
    title: 'Jueves',
    value: 'THURSDAY',
  },
  {
    title: 'Viernes',
    value: 'FRIDAY'
  },
  {
    title: 'Sábado',
    value: 'SUNDAY'
  },
  {
    title: 'Domingo',
    value: 'SATURDAY'
  }
];

export const banks = [
  {
    title: 'ABC Capital',
    value: 'abc capital',
    code: null,
  },
  {
    title: 'Accendo Banco',
    value: 'accendo banco',
    code: null,
  },
  {
    title: 'Actinver',
    value: 'actinver',
    code: null,
  },
  {
    title: 'Afirme',
    value: 'afirme',
    code: 'BAFIR',
  },
  {
    title: 'Azteca',
    value: 'azteca',
    code: 'BAZTE',
  },
  {
    title: 'BANAMEX',
    value: 'banamex',
    code: 'BANAM',
  },
  {
    title: 'BanBajío',
    value: 'banbajio',
    code: 'BAJIO',
  },
  {
    title: 'Banco Base',
    value: 'banco base',
    code: null,
  },
  {
    title: 'BanCoppel',
    value: 'bancoppel',
    code: 'COPEL',
  },
  {
    title: 'Bancrea',
    value: 'bancrea',
    code: null,
  },
  {
    title: 'Banorte',
    value: 'banorte',
    code: 'BBANO',
  },
  {
    title: 'Banregio',
    value: 'banregio',
    code: 'BANRE',
  },
  {
    title: 'Bansí',
    value: 'bansi',
    code: 'BANSI',
  },
  {
    title: 'BBVA',
    value: 'bbva',
    code: 'BACOM',
  },
  {
    title: 'CIBanco',
    value: 'cibanco',
    code: null,
  },
  {
    title: 'Compartamos',
    value: 'compartamos',
    code: 'BCOMP',
  },
  {
    title: 'Consubanco',
    value: 'consubanco',
    code: 'CONSU',
  },
  {
    title: 'Famsa',
    value: 'famsa',
    code: 'FAMSA',
  },
  {
    title: 'Finterra',
    value: 'finterra',
    code: null,
  },
  {
    title: 'Forjadores',
    value: 'forjadores',
    code: null,
  },
  {
    title: 'HSBC',
    value: 'hsbc',
    code: 'BITAL',
  },
  {
    title: 'Inbursa',
    value: 'inbursa',
    code: 'BINBU',
  },
  {
    title: 'Invex',
    value: 'invex',
    code: 'BINVE',
  },
  {
    title: 'Mifel',
    value: 'mifel',
    code: 'MIFEL',
  },
  {
    title: 'Monex',
    value: 'monex',
    code: 'CMCA',
  },
  {
    title: 'Multiva',
    value: 'multiva',
    code: 'MULTI',
  },
  {
    title: 'PagaTodo',
    value: 'pagatodo',
    code: null,
  },
  {
    title: 'Sabadell',
    value: 'sabadell',
    code: null,
  },
  {
    title: 'Santander',
    value: 'santander',
    code: 'BANME',
  },
  {
    title: 'Scotiabank',
    value: 'scotiabank',
    code: 'COMER',
  },
  {
    title: 'Ve por Más',
    value: 've por mas',
    code: 'DRESD',
  },
];

export const layoutFieldDescriptors = {
  layout: {
    length: 5,
  },
  chargeAccount: {
    length: 18,
    align: 'right',
    padding: ' ',
  },
  depositAccount: {
    length: 20,
    align: 'right',
    padding: ' ',
  },
  bankCode: {
    length: 5,
    type: 'bankCode',
  },
  beneficiary: {
    length: 40,
    align: 'left',
    padding: ' ',
  },
  branchOfficeCode: {
    length: 4,
  },
  amount: {
    length: 18,
    padding: '0',
    align: 'right',
    type: 'currency',
  },
  cityCode: {
    length: 5,
  },
  concept: {
    type: 'concept',
    align: 'left',
    padding: ' ',
    length: 40,
  },
  orderingReference: {
    type: 'accountId',
    align: 'right',
    length: 7,
  },
  beneficiaryEmail: {
    align: 'left',
    padding: ' ',
    length: 39,
  },
  controlDigit: {
    required: true,
    length: 1,
  },
  dateOfApplication: {
    length: 8,
    type: 'date',
    format: 'DDMMAAAA',
  },

};

export const massiveTransactionLayouts = {
  LTX05: {
    length: 203,
    fields: [
      {
        fieldName: 'layout',
        fieldRules: layoutFieldDescriptors.layout,
        required: true,
        value: 'LTX05',
        position: [1, 5],
      },
      {
        fieldName: 'chargeAccount',
        fieldRules: layoutFieldDescriptors.chargeAccount,
        position: [6, 23],
        required: true,
        value: '65508270300',
      },
      {
        fieldName: 'depositAccount',
        fieldRules: layoutFieldDescriptors.depositAccount,
        position: [24, 43],
        required: true,
      },
      {
        fieldName: 'bankCode',
        fieldRules: layoutFieldDescriptors.bankCode,
        position: [44, 48],
        required: true,
      },
      {
        fieldName: 'beneficiary',
        fieldRules: layoutFieldDescriptors.beneficiary,
        position: [49, 88],
        required: true,
      },
      {
        fieldName: 'branchOfficeCode',
        fieldRules: layoutFieldDescriptors.branchOfficeCode,
        position: [89, 92],
        required: true,
        value: '0000',
      },
      {
        fieldName: 'amount',
        fieldRules: layoutFieldDescriptors.amount,
        position: [93, 110],
        required: true,
      },
      {
        fieldName: 'cityCode',
        fieldRules: layoutFieldDescriptors.cityCode,
        position: [111, 115],
        value: '00000',
      },
      {
        fieldName: 'concept',
        fieldRules: layoutFieldDescriptors.concept,
        position: [116, 155],
        required: false,
      },
      {
        fieldName: 'orderingReference',
        fieldRules: layoutFieldDescriptors.orderingReference,
        position: [156, 162],
        required: false,
      },
      {
        fieldName: 'beneficiaryEmail',
        fieldRules: layoutFieldDescriptors.beneficiaryEmail,
        position: [163, 202],
      },
      {
        fieldName: 'controlDigit',
        fieldRules: layoutFieldDescriptors.controlDigit,
        position: [203, 203],
        value: '1',
      },
    ],
  },
  LTX07: {
    length: 150,
    fields: [
      {
        fieldName: 'layout',
        fieldRules: layoutFieldDescriptors.layout,
        required: true,
        value: 'LTX07',
        position: [1, 5],
      },
      {
        fieldName: 'chargeAccount',
        fieldRules: layoutFieldDescriptors.chargeAccount,
        position: [6, 23],
        required: true,
        value: '65508270300',
      },
      {
        fieldName: 'depositAccount',
        fieldRules: layoutFieldDescriptors.depositAccount,
        position: [24, 43],
        required: true,
      },
      {
        fieldName: 'amount',
        fieldRules: layoutFieldDescriptors.amount,
        position: [44, 61],
        required: true,
      },
      {
        fieldName: 'concept',
        fieldRules: layoutFieldDescriptors.concept,
        position: [62, 101],
        required: false,
      },
      {
        fieldName: 'dateOfApplication',
        fieldRules: layoutFieldDescriptors.dateOfApplication,
        position: [102, 110],
        required: true,
      },
      {
        fieldName: 'beneficiaryEmail',
        fieldRules: layoutFieldDescriptors.beneficiaryEmail,
        position: [111, 150],
      },
    ],
  },
};

export const drawerData = [{
  name: 'Inicio',
  val: 'inicio',
  path: '/home'
},{
  name: 'Ventas realizadas',
  val: 'ventas',
  path: '/already-seller'
},{
  name: 'Transferencias',
  val: 'transferencias',
  path: '/transfer'
},{
  name: 'Comisiones',
  val: 'comisiones',
  path: '/commissions'
}]

export const headCells = [{
      id: 'store', numeric: false, disablePadding: false, label: 'Tienda',
  }, {
      id: 'holder', numeric: false, disablePadding: false, label: 'Titular',
  }, {
      id: 'phone', numeric: false, disablePadding: false, label: 'Teléfono',
  }, {
      id: 'date', numeric: false, disablePadding: false, label: 'Fecha de operación',
  }, {
      id: 'amount', numeric: false, disablePadding: false, label: 'Importe',
  },
];

export const headCellsSeller = [
  {
      id: 'business', numeric: false, disablePadding: false, label: 'Negocio',
  },
  {
      id: 'holder', numeric: false, disablePadding: false, label: 'Titular',
  },
  {
      id: 'date', numeric: false, disablePadding: false, label: 'Fecha de operación',
  },
  {
      id: 'amount', numeric: false, disablePadding: false, label: 'Importe',
  },
  {
      id: 'status', numeric: false, disablePadding: false, label: 'Estatús',
  },
  {
      id: 'invoice', numeric: false, disablePadding: false, label: 'Folio de operación',
  },
  {
      id: 'commission', numeric: false, disablePadding: false, label: 'Comisión',
  },
];