import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const Text = ({ children, ...others }) => {
  const otherStyle = others?.style || {}
  return(
    <Typography {...others} style={{ fontFamily: 'Poppins', lineHeight: 1.3, ...otherStyle }}>
      {children}
    </Typography>
  )
};

export default Text;

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
};

Text.defaultProps = {
  children: '',
};