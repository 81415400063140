import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Phone, Lock, VisibilityOff, Visibility } from '@material-ui/icons';

import logo from '../../assets/images/logo.svg';
import {
  Text,
  TextInput,
  Button
} from '../../components';

export const LoginPage = ({
  classes,
  values,
  handleChangeInput,
  loginAction,
  isVisible, 
  setIsVisible
}) => {
  return (
    <div 
      className={classes.page}
    >
      <div 
        className={classes.container}
      >
        <img 
          className={classes.appLogo} 
          src={logo} 
          alt="logo" 
        />
      </div>
      <div 
        className={classes.loginContainer} 
        bgcolor="white"
      >
          <Text 
            variant="h1"
          >
            Bienvenid@ a YoFio
          </Text>
          <Text 
            variant="h2" 
            className={classes.subTitleText}
          >
            Inicia sesión en tu cuenta
          </Text>
          <div
            className={classes.separationInput}
          >
            <TextInput 
              className={classes.inputContainer}
              variant="outlined"
              placeholder={'Número telefónico'}
              value={values.phone_number}
              onChange={handleChangeInput('phone_number')}
              InputProps={{
                  startAdornment: (
                      <InputAdornment position="start">
                          <Phone />
                      </InputAdornment>
                  ),
                  classes: {
                      input: classes.fontSize,
                  }
              }}
            />
          </div>
          <div
            className={classes.separationInput}
          >
            <TextInput 
              className={classes.inputContainer}
              variant="outlined"
              placeholder={'Contraseña'}
              value={values.pin}
              onChange={handleChangeInput('pin')}
              type={isVisible ? "text" : "password"}
              InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div onClick={() => setIsVisible((prevState) => !prevState)} style={{ cursor: 'pointer' }}>
                        { isVisible ? <VisibilityOff /> : <Visibility /> }
                      </div>
                    </InputAdornment>
                  ),
                  classes: {
                      input: classes.fontSize,
                  }
              }}
            />
          </div>
          <div
            className={classes.separationInput}
          >
            <Button
              className={classes.buttonLogIn}
              variant='contained'
              color='primary'
              isDisabled={ values.phone_number !== '' && values.phone_number.length === 10 && values.pin !== '' ? false : true }
              onClick={() => loginAction()}
            >
              Iniciar sesión
            </Button>
          </div>
      </div>
    </div>
  );
};

export default LoginPage;

LoginPage.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  handleChangeInput: PropTypes.func,
  loginAction: PropTypes.func,
  isVisible: PropTypes.bool, 
  setIsVisible: PropTypes.func
};

LoginPage.defaultProps = {
  classes: {},
  values: {},
  handleChangeInput: () => { return },
  loginAction: () => { return },
  isVisible: false,
  setIsVisible: () => { return }
};
