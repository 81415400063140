import axios from 'axios';

import { appService } from '../services/app.service';

const clientAuthentication = (() => axios.create({
  baseURL: appService.config.paths.apiAuthentication.basePath,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
}))();

clientAuthentication.interceptors.request.use((config) => {
  console.log(`%c REQUEST: %s`, 'color: #0000FF; font-weight: bold', JSON.stringify(config));
  return config;
});

// Intercept all responses
clientAuthentication.interceptors.response.use(
  (response) => {
    console.log(`%c ${response.status} - ${(response.config)}:`, 'color: #008000; font-weight: bold', response);
    return response;
  },
  (error) => {
    if (error.response) {
      const config = error.response.config;
      console.log(`%c ${error.response.status} - ${JSON.stringify(config)}:`, 'color: #a71d5d; font-weight: bold', error.response);
    }
    return Promise.reject(error);
  }
);

export default clientAuthentication;