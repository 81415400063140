import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { saveAs } from 'file-saver'

import HomePage from './Home';
import useStyles from './Home.styles';
import { headCells } from '../../constants/constants';
import {
    getWholesalerSalesBranch
} from '../../redux/actions/wholesaler.action';
import images from '../../assets/images';

const Home = () => {
    const headquarterId = localStorage.getItem('headquarter_id');

    const classes = useStyles();
    const dispatch = useDispatch();
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [fullHistory, setFullHistory] = useState(true);

    const {
        branchOfficeSelected,
        salesBranchResponse
    } = useSelector((state) => state.wholesalerReducer);
    
    useEffect(() => {
        if(Object.keys(branchOfficeSelected).length !== 0) getWholesalerSalesBranchAction();
    }, [branchOfficeSelected, minDate, maxDate, fullHistory]);

    const getWholesalerSalesBranchAction = () => {
        let query = {};
        if(fullHistory) {
            query = {
                full_history: fullHistory
            }
        } else {
            query = {
                start_date: moment(minDate).format('YYYY-MM-DD'), 
                end_date: moment(maxDate).format('YYYY-MM-DD')
            }
        }
        dispatch(getWholesalerSalesBranch(headquarterId, branchOfficeSelected.branch_office_id, query));
    }

    const downloadQR = () => {
        const image = salesBranchResponse?.data && salesBranchResponse.data?.qr_path ? salesBranchResponse.data.qr_path : images.codigoQR
        saveAs(image, 'image.jpg')
    }

    return (
        <>
            {   Object.keys(salesBranchResponse).length !== 0 &&
                <HomePage  
                    headCells={headCells}
                    classes={classes}
                    minDate={minDate}
                    setMinDate={setMinDate}
                    maxDate={maxDate}
                    setMaxDate={setMaxDate}
                    branchName={branchOfficeSelected.trade_name}
                    dataBranch={salesBranchResponse.data}
                    fullHistory={fullHistory}
                    setFullHistory={() => setFullHistory(prevState => !prevState)}
                    downloadQR={downloadQR}
                />
            }
        </>
    )
}

export default Home;