import React from 'react';
import TextField from '@material-ui/core/TextField';
import { styled } from '@material-ui/styles';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000',
  },
  '& .MuiInput-underline:after': {
    borderColor: '#3050ff',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#e0e3e5',
    },
    '&:hover fieldset': {
      borderColor: '#3050ff',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#3050ff',
    },
  },
});

const TextInput = (props) => (
  <CssTextField 
    {...props}
    style={{ fontFamily: 'Poppins', lineHeight: 1.3 }}
  >
    { props.children }
  </CssTextField>
);

export default TextInput;