import React from 'react';

import BoxLayoutUI from './BoxLayout';

const BoxLayout = (props) => {
    return(
        <BoxLayoutUI  { ...props } />
    )
}

export default BoxLayout;