import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import { PageContainer } from './components';
import App from './containers/App';

import Login from './containers/Login';
import Home from './containers/Home';
import AlreadySeller from './containers/AlreadySeller';
import Transfer from './containers/Transfer';
import Commissions from './containers/Commissions';
import NotFoundPage from './containers/NotFound';

const Routes = () => (
  <Router>
    <App>
      <Switch>
        <Route exact path="/login" component={Login} />
        <PageContainer>
          <Route exact path="/home" component={Home} />
          <Route exact path="/already-seller" component={AlreadySeller} />
          <Route exact path="/transfer" component={Transfer} />
          <Route exact path="/commissions" component={Commissions} />
        </PageContainer>
        <Route path="**" component={NotFoundPage} />
      </Switch>
    </App>
  </Router>
);

export default Routes;

