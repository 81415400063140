import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import AlreadySellerUI from './AlreadySeller';
import useStyles from './AlreadySeller.styles';
import { headCellsSeller } from '../../constants/constants';
import {
    getWholesalerSalesBranch,
    downloadWholesalerSalesReport,
    resetDownloadWholesalerSalesReport
} from '../../redux/actions/wholesaler.action';

const AlreadySeller = () => {
    const headquarterId = localStorage.getItem('headquarter_id');

    const classes = useStyles();
    const dispatch = useDispatch();
    const csvLink = useRef();
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());

    const {
        branchOfficeSelected,
        salesBranchResponse,
        downloadReportResponse
    } = useSelector((state) => state.wholesalerReducer);
    
    useEffect(() => {
        if(Object.keys(branchOfficeSelected).length !== 0) getWholesalerSalesBranchAction();
    }, [branchOfficeSelected, minDate, maxDate]);

    useEffect(() => {
        if(Object.keys(downloadReportResponse).length !== 0) {
            setTimeout(() => {
                csvLink.current.link.click();
                dispatch(resetDownloadWholesalerSalesReport());
            }, 2000);
        }
    }, [downloadReportResponse]);

    const downloadCSV = () => {
        let query = {
            start_date: moment(minDate).format('YYYY-MM-DD'), 
            end_date: moment(maxDate).format('YYYY-MM-DD')
        }
        dispatch(downloadWholesalerSalesReport(headquarterId, branchOfficeSelected.branch_office_id, query));
    }

    const getWholesalerSalesBranchAction = () => {
        let query = {
            start_date: moment(minDate).format('YYYY-MM-DD'), 
            end_date: moment(maxDate).format('YYYY-MM-DD')
        }
        dispatch(getWholesalerSalesBranch(headquarterId, branchOfficeSelected.branch_office_id, query));
    }

    return (
        <>
            {   Object.keys(salesBranchResponse).length !== 0 &&
                <AlreadySellerUI  
                    classes={classes}
                    headCells={headCellsSeller}
                    minDate={minDate}
                    setMinDate={setMinDate}
                    maxDate={maxDate}
                    setMaxDate={setMaxDate}
                    dataBranch={salesBranchResponse.data}
                    downloadCSV={downloadCSV}
                />
            }
            <CSVLink
                data={downloadReportResponse?.data || ''}
                filename={'Ventas realizadas'}
                className="hidden"
                ref={csvLink}
                target="_blank"
            />
        </>
    )
}

export default AlreadySeller;