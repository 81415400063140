import React from 'react';
import PropTypes from 'prop-types';
import ButtonM from '@material-ui/core/Button';

const Button = ({ children, isDisabled, customStyle, customButtonStyle, ...others }) => {
  return (
    <ButtonM 
      style={{ customButtonStyle, fontFamily: 'Poppins', fontSize: '18px', ...customStyle }}
      disabled={ isDisabled }
      { ...others }
    >
      { children }
    </ButtonM>
  );
};

export default Button;

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  isDisabled: PropTypes.bool,
  customStyle: PropTypes.object,
  customButtonStyle: PropTypes.object
};