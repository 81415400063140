import * as ACTION_AUTHENTICATION from '../actionTypes/authentication';
import * as ACTION_WHOLESALER from '../actionTypes/wholesaler';

export const logOutAction = () => async (dispatch) => {
    localStorage.setItem('clientToken', null);
    localStorage.setItem('phone_number', null);
    localStorage.setItem('headquarter_id', null);
    dispatch({
        type: ACTION_AUTHENTICATION.RESET_AUTHENTICATION,
    });
    dispatch({
        type: ACTION_WHOLESALER.RESET_GET_BASIC_INFO,
    });
    dispatch({
        type: ACTION_WHOLESALER.RESET_GET_SALES_BRANCH,
    });
    dispatch({
        type: ACTION_WHOLESALER.RESET_DOWNLOAD_REPORT,
    });
};