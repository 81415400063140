import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import useStyles from './App.styles';
import { BoxLayout, Loader, ErrorHandler } from '../components';
import { darkTheme, lightTheme } from './Theme';
import {
  resetWholesalersState,
} from '../redux/actions/authentication.actions';
import {
  resetWholesalerBasicInfo,
  resetWholesalerSalesBranch,
  resetDownloadWholesalerSalesReport
} from '../redux/actions/wholesaler.action';
import {
  logOutAction
} from '../redux/actions/general.actions';

const App = (props) => {
  const { children } = props;

  const loadingHandler = useRef(null);
  const errorHandler = useRef(null);

  const dispatch = useDispatch();
  const history = useHistory(); 

  const classes = useStyles();
  const { isLightThemeSelected } = useState(true);

  const {
    loading: authenticationLoading,
    error: authenticationError,
    authenticationResponse
  } = useSelector((state) => state.authenticationReducer);

  const {
    loading: wholesalerLoading,
    error: wholesalerError,
  } = useSelector((state) => state.wholesalerReducer);

  useEffect(() => {
    let clientToken = localStorage.getItem('clientToken');
    if(clientToken === null || clientToken === undefined) {
      history.replace('/login');
    }
  }, []);

  useEffect(() => {
    if(Object.keys(authenticationResponse).length !== 0) {
      let configData = JSON.parse(authenticationResponse.config.data);
      localStorage.setItem('clientToken', `${authenticationResponse.data.access_type} ${authenticationResponse.data.access_token}`);
      localStorage.setItem('phone_number', configData.phone_number);
      localStorage.setItem('headquarter_id', authenticationResponse.data.account_id);
      history.replace('/home');
    }
  }, [authenticationResponse]);
  
  useEffect(() => {
    const {
      AUTHENTICATION
    } = authenticationLoading;

    if (AUTHENTICATION) {
      loadingHandler.current.show();
    } else {
      loadingHandler.current.hide();
    }
  }, [authenticationLoading]);

  useEffect(() => {
    const {
      AUTHENTICATION,
    } = authenticationError;

    if (AUTHENTICATION) {
      if (AUTHENTICATION.response?.status === 403) {
        errorHandler.current.show(AUTHENTICATION);
        dispatch(logOutAction());
        history.replace('/login');
      } else {
        errorHandler.current.show(AUTHENTICATION);
      }
    }
  }, [authenticationError]);

  useEffect(() => {
    const {
      GET_BASIC_INFO,
      GET_SALES_BRANCH,
      DOWNLOAD_REPORT
    } = wholesalerLoading;

    if (GET_BASIC_INFO, GET_SALES_BRANCH, DOWNLOAD_REPORT) {
      loadingHandler.current.show();
    } else {
      loadingHandler.current.hide();
    }
  }, [wholesalerLoading]);

  useEffect(() => {
    const {
      GET_BASIC_INFO,
      GET_SALES_BRANCH,
      DOWNLOAD_REPORT,
    } = wholesalerError;

    if (GET_BASIC_INFO) {
      if (GET_BASIC_INFO.response?.status === 403) {
        errorHandler.current.show(GET_BASIC_INFO);
        dispatch(logOutAction())
        history.replace('/login');
      } else {
        errorHandler.current.show(GET_BASIC_INFO);
      }
    }

    if (GET_SALES_BRANCH) {
      if (GET_SALES_BRANCH.response?.status === 403) {
        errorHandler.current.show(GET_SALES_BRANCH);
        dispatch(logOutAction())
        history.replace('/login');
      } else {
        errorHandler.current.show(GET_SALES_BRANCH);
      }
    }

    if (DOWNLOAD_REPORT) {
      if (DOWNLOAD_REPORT.response?.status === 403) {
        errorHandler.current.show(DOWNLOAD_REPORT);
        dispatch(logOutAction())
        history.replace('/login');
      } else {
        errorHandler.current.show(DOWNLOAD_REPORT);
      }
    }
  }, [wholesalerError]);

  const onCloseErrorAction = () => {
    const {
      AUTHENTICATION,
    } = authenticationError;
    const {
      GET_BASIC_INFO,
      GET_SALES_BRANCH,
      DOWNLOAD_REPORT,
    } = wholesalerError;

    if(AUTHENTICATION) {
      dispatch(resetWholesalersState());
    }

    if(GET_BASIC_INFO) {
      dispatch(resetWholesalerBasicInfo());
    }

    if(GET_SALES_BRANCH) {
      dispatch(resetWholesalerSalesBranch());    
    }

    if(DOWNLOAD_REPORT) {
      dispatch(resetDownloadWholesalerSalesReport());   
    }
    
    errorHandler.current.hide();
  }
  
  return (
    <MuiThemeProvider theme={isLightThemeSelected ? lightTheme : darkTheme}>
      <BoxLayout className={classes.container}>
        { children }
        <Loader
          reference={(reference) => {
            loadingHandler.current = reference;
          }}
        />
        <ErrorHandler
          actionText="Entendido"
          reference={(reference) => {
            errorHandler.current = reference;
          }}
          onClose={() => {
            onCloseErrorAction();
          }}
        />
      </BoxLayout>
    </MuiThemeProvider>
  );
};

App.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.shape({}),
};

App.defaultProps = {
  classes: {},
};

export default App;
