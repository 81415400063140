import React from 'react';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';

import useStyles from './ErrorHandler.styles';
import { Button, BoxLayout, Text } from '..';

const ErrorHandler = ({
    isVisible,
    message,
    close,
    actionText,
}) => {
    const classes = useStyles();

    return(
        <Modal
            open={isVisible}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.errorContainer}
        >
            <BoxLayout className={ classes.container }>
                <Text variant='h3' style={{ color: '#2b2c49' }} align='justify'>
                    ¡Parece que hubo un error!
                </Text>
                <Text className={ classes.elementMessage } variant='h4' style={{ color: '#2b2c49' }} align='justify'>
                    { message.trim() }
                </Text>
                <Button className={ classes.button } variant='contained' color='primary'  onClick={() => close()}>{ actionText || 'Continuar' }</Button>
            </BoxLayout>
        </Modal>
    )
}

export default ErrorHandler;

ErrorHandler.propTypes = {
    isVisible: PropTypes.bool,
    message: PropTypes.string,
    close: PropTypes.func,
    actionText: PropTypes.string
};
