import React from 'react';
import PropTypes, { any } from 'prop-types';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow, 
  TableCell,
  TableHead,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns'; 
import esLocale from "date-fns/locale/es";
import moment from 'moment';

import {
  Text,
  Button,
} from '../../components';

export const AlreadySeller = ({
  classes,
  headCells,
  minDate,
  setMinDate,
  maxDate,
  setMaxDate,
  dataBranch,
  downloadCSV
}) => {
  const tableElements = () => {
    return dataBranch.sales_summary.sales.map(sales => {
      return (
        <TableRow>
          <TableCell>
            <Text className={classes.textCellTable}>
              {sales.shop_name}
            </Text>
          </TableCell>
          <TableCell>
            <Text className={classes.textCellTable}>
              {sales.shop_owner_name}
            </Text>
          </TableCell>
          <TableCell>
            <Text className={classes.textCellTable}>
              {moment(sales.movement_date).format('DD MMM YYYY hh:mm a')}
            </Text>
          </TableCell>
          <TableCell>
            <Text className={classes.textCellTable}>
              $ {sales.amount}
            </Text>
          </TableCell>
          <TableCell>
            <Text className={classes.textCellTable} style={{ color: sales.status === 'PAID' ? 'green' : '#52575a' }} >
              {sales.status === 'PAID' ? 'Pagado' : 'Pendiente de pago'}
            </Text>
          </TableCell>
          <TableCell>
            <Text className={classes.textCellTable}>
              -
            </Text>
          </TableCell>
          <TableCell>
            <Text className={classes.textCellTable}>
              $ 0
            </Text>
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <div>
      <div 
        className={classes.page}
      >
        <div className={classes.viewPage}>
          <div>
            <Text className={classes.title} variant="h2" color="initial" aling="justify">Ventas Realizadas</Text>
          </div>
          <div className={classes.topSecondContainer}>
            <div className={classes.calendarContainer}>
                <div className={classes.buttonCalendar}>
                  <Text className={classes.textButton}>Desde</Text>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                      maxDate={maxDate}
                      value={minDate}
                      onChange={value => setMinDate(value)}
                      animateYearScrolling
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className={classes.buttonCalendar}>
                  <Text className={classes.textButton}>Hasta</Text>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                      minDate={minDate}
                      value={maxDate}
                      onChange={value => setMaxDate(value)}
                      animateYearScrolling
                    />
                  </MuiPickersUtilsProvider>
                </div>
            </div>
            <Button 
              onClick={() => downloadCSV()}
              customButtonStyle={styles.buttonDowland}
            >
              Descargar CSV
            </Button>
          </div>
        </div>
        <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      align="center"
                      scope="col"
                    >
                      <Text className={classes.titleLabel}>{headCell.label}</Text>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                { 
                  dataBranch?.sales_summary && dataBranch.sales_summary?.sales && dataBranch.sales_summary.sales.length > 0 &&
                  tableElements() 
                }
              </TableBody>
            </Table>
          </TableContainer>
      </div>
    </div>
  );
};

export default AlreadySeller;

const styles = {
  buttonDowland: {
    backgroundColor: '#3050ff',
    color: '#ffffff',
    fontSize: 12,
    paddingLeft: 20, 
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 10,
  },
}

AlreadySeller.propTypes = {
  classes: PropTypes.object,
  headCells: PropTypes.array,
  minDate: PropTypes.string,
  setMinDate: PropTypes.func,
  maxDate: PropTypes.string,
  setMaxDate: PropTypes.func,
  dataBranch: PropTypes.object,
  downloadCSV: PropTypes.func
};

AlreadySeller.defaultProps = {
  classes: {},
  headCells: [],
  minDate: '',
  setMinDate: () => { },
  maxDate: '',
  setMaxDate: () => { },
  dataBranch: {},
  downloadCSV: () => { }
};
