import React from 'react';

import ButtonUI from './Button';

const Button = (props) => {
    const { isDisabled, customButtonStyle } = props;
    
    const customStyle = !isDisabled ? {
        backgroundColor: '#3050ff',
        borderRadius: 20,
        marginRight: 20,
        marginLeft: 20,
        paddingTop: 10,
        paddingBottom: 10
    } : {
        borderRadius: 20,
        marginRight: 20,
        marginLeft: 20,
        paddingTop: 10,
        paddingBottom: 10
    }

    return <ButtonUI { ...props } customStyle={{ ...customStyle, ...customButtonStyle }} /> 
}

export default Button;