import React from 'react';
import { useDispatch } from 'react-redux';

import CommissionsUI from './Commissions';
import useStyles from './Commissions.styles';

const Commissions = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <CommissionsUI  
            classes={classes}
        />
    )
}

export default Commissions;