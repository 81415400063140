import React from 'react';
import { useDispatch } from 'react-redux';

import TransferUI from './Transfer';
import useStyles from './Transfer.styles';

const Transfer = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <TransferUI 
            classes={classes}
        />
    )
}

export default Transfer;