export const INIT_GET_BASIC_INFO = 'WHOLESALER::INIT_GET_BASIC_INFO';
export const RESPONSE_GET_BASIC_INFO = 'WHOLESALER::RESPONSE_GET_BASIC_INFO';
export const ERROR_GET_BASIC_INFO = 'WHOLESALER::ERROR_GET_BASIC_INFO';
export const RESET_GET_BASIC_INFO = 'WHOLESALER::RESET_GET_BASIC_INFO';

export const SELECT_BRANCH_OFFICE = 'WHOLESALER::SELECT_BRANCH_OFFICE'

export const INIT_GET_SALES_BRANCH = 'WHOLESALER::INIT_GET_SALES_BRANCH';
export const RESPONSE_GET_SALES_BRANCH = 'WHOLESALER::RESPONSE_GET_SALES_BRANCH';
export const ERROR_GET_SALES_BRANCH = 'WHOLESALER::ERROR_GET_SALES_BRANCH';
export const RESET_GET_SALES_BRANCH = 'WHOLESALER::RESET_GET_SALES_BRANCH';

export const INIT_DOWNLOAD_REPORT = 'WHOLESALER::INIT_DOWNLOAD_REPORT';
export const RESPONSE_DOWNLOAD_REPORT = 'WHOLESALER::RESPONSE_DOWNLOAD_REPORT';
export const ERROR_DOWNLOAD_REPORT = 'WHOLESALER::ERROR_DOWNLOAD_REPORT';
export const RESET_DOWNLOAD_REPORT = 'WHOLESALER::RESET_DOWNLOAD_REPORT';