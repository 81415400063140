import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.5,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#ea4b4b',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.5,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  appLogo: {
    maxWidth: 400,
    width: '80%',
  },
  subTitleText: {
    marginTop: 10
  },
  separationInput: {
    marginTop: 40
  },
  inputContainer: {
    width: 400,
  },
  fontSize: {
    fontSize: '18px'
  },
  buttonLogIn: {
    width: 400
  }
}));

export default useStyles;