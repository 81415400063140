import * as ACTION from '../actionTypes/wholesaler';
import * as wholesalerService from '../../services/wholesaler.service';

export const getWholesalerBasicInfo = (headquarterId) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_GET_BASIC_INFO,
    });

    try {
        const basicInfoResponse = await wholesalerService.getWholesalerBasicInfo(headquarterId);
        if (basicInfoResponse) {
            dispatch({
                type: ACTION.RESPONSE_GET_BASIC_INFO,
                result: basicInfoResponse,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_GET_BASIC_INFO,
            error: error,
        });
    }
};

export const resetWholesalerBasicInfo = () => async (dispatch) => {
    dispatch({
        type: ACTION.RESET_GET_BASIC_INFO,
    });
};

export const selectBranchOffice = (branchOffice) => async (dispatch) => {
    dispatch({
        type: ACTION.SELECT_BRANCH_OFFICE,
        result: branchOffice
    });
};

export const getWholesalerSalesBranch = (headquarterId, branchOfficeId, queries) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_GET_SALES_BRANCH,
    });

    try {
        const salesBranchResponse = await wholesalerService.getWholesalerSalesBranch(headquarterId, branchOfficeId, queries);
        if (salesBranchResponse) {
            dispatch({
                type: ACTION.RESPONSE_GET_SALES_BRANCH,
                result: salesBranchResponse,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_GET_SALES_BRANCH,
            error: error,
        });
    }
};

export const resetWholesalerSalesBranch = () => async (dispatch) => {
    dispatch({
        type: ACTION.RESET_GET_SALES_BRANCH,
    });
};

export const downloadWholesalerSalesReport = (headquarterId, branchOfficeId, queries) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_DOWNLOAD_REPORT,
    });

    try {
        const salesBranchResponse = await wholesalerService.downloadWholesalerSalesReport(headquarterId, branchOfficeId, queries);
        if (salesBranchResponse) {
            dispatch({
                type: ACTION.RESPONSE_DOWNLOAD_REPORT,
                result: salesBranchResponse,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_DOWNLOAD_REPORT,
            error: error,
        });
    }
};

export const resetDownloadWholesalerSalesReport = () => async (dispatch) => {
    dispatch({
        type: ACTION.RESET_DOWNLOAD_REPORT,
    });
};