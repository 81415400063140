import React from 'react';

import CheckBoxUI from './CheckBox';

const CheckBox = (props) => {
    return(
        <CheckBoxUI {...props} />
    )
}

export default CheckBox;