import React from 'react';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

import useStyles from './Loader.styles';

const Loader = ({
    isVisible
}) => {
    const classes = useStyles();

    return(
        <Modal
            open={isVisible}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.loader}
        >
            <CircularProgress />
        </Modal>
    )
}

export default Loader;

Loader.propTypes = {
    isVisible: PropTypes.bool
};