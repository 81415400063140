import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import LoginUI from './Login';
import useStyles from './Login.styles';
import {
    wholesalersAuthentication
} from '../../redux/actions/authentication.actions';

const Login = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [values, setValues] = useState({
        phone_number: '',
        pin: ''
    });
    const [isVisible, setIsVisible] = useState(false);

    const handleChangeInput = (prop) => (event) => {
        let { value } = event.target;
        if (prop === 'phone_number') {
            if((validateNumbers(value) && value.length < 11) || value === '') {
                setValues({ ...values, [prop]: value });
            }
        } else {
            setValues({ ...values, [prop]: value });
        }
    };

    const validateNumbers = value => {
        const numbersReGex = /^[0-9]+$/;
        return value.match(numbersReGex) ? true : false
    }

    const loginAction = () => {
        const body = {
            account_type_id: 'WS',
            ...values,
            phone_number: `+52${values.phone_number}`
        }
        dispatch(wholesalersAuthentication(body));
    }

    return (
        <LoginUI  
            classes={classes}
            values={values}
            handleChangeInput={handleChangeInput}
            loginAction={loginAction}
            isVisible={isVisible} 
            setIsVisible={setIsVisible}
        />
    )
}

export default Login;